import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/prueba.png";
import HeaderSocial from "./HeaderSocial";

const Header = () => {
  return (
    <section id="header">
      <header>
        <div className="container header__container">
          <div>
            <div className="svg-wrap">
              <svg viewBox="0 0 400 60" xmlns="http://www.w3.org/2000/svg">
                <path
                  id="svg_marker"
                  d="m 3.518915,27.827324 c 55.429038,4.081 111.581115,5.822 167.117815,2.867 22.70911,-1.208 45.39827,-0.601 68.126,-0.778 28.38172,-0.223 56.76078,-1.024 85.13721,-1.33 24.17378,-0.261 48.4273,0.571 72.58114,0.571"
                ></path>
              </svg>
            </div>

            <h5 className="hello-title">Hello I'm</h5>
            <h1>Marc López Borràs</h1>
            <div className="link-svgmarker">
              <a className="underline-text" href="">
                Fullstack Technical Artist
                <svg className="link-svgline">
                  <use xlinkHref="#svg_marker" />
                </svg>
              </a>
            </div>
            {/* Buttons from Header in CTA Component */}
            <CTA />
            {/* HeaderSocial Component */}
            <HeaderSocial />
            {/* Asset image from Header */}
            <div className="header__me">
              <div className="header__me-image">
                <img style={{ paddingTop: 50 }} src={ME} alt="me" />
              </div>
              
            </div>
            {/* Link to contact section */}
            <a href="#contact" className="scroll__down">
              Scroll Down
            </a>
          </div>
        </div>
      </header>
    </section>
  );
};

export default Header;
