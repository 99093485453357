import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

function Particle(){

  const particlesInit = useCallback(async engine => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
}, []);

const particlesLoaded = useCallback(async container => {
    await console.log(container);
}, []);

  return (
<Particles
id="tsparticles"
init={particlesInit}
loaded={particlesLoaded}
options={{

  "fullScreen": { 

    "enable":true,
    "zIndex": -1
  },

  "particles": {
    "number": {
      "value": 14,
      "density": {
        "enable": true,
        "value_area": 4208.621057034402
      }
    },
    "color": {
      "value": "#f0f567"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#16123f"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.5,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value":230,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 0,
        "size_min": 0,
        "sync": false
      }
    },
    "line_linked": {
      "enable": true,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 0.5,
      "direction": "none",
      "random": false,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": false,
        "mode": "repulse"
      },
      "onclick": {
        "enable": false,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 767.2327672327673,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 563.4365634365635,
        "size": 335.6643356643357,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true


}}


/>
  )
}

export default Particle