import React from "react";
import "./about.css";
import ME from "../../assets/me-about.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <div className="link-svgmarker">
        <center>
          <a className="underline-text">
            {" "}
            Get To Know
            <svg className="link-svgline">
              <use xlinkHref="#svg_marker" />
            </svg>
          </a>
        </center>
      </div>
      <h2>About Me </h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3 years experience</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Game Projects</h5>
              <small>Unity and Unreal Engine </small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>FullStack Projects</h5>
              <small>Backend and FrontEnd </small>
            </article>
          </div>
          <p>
          I am an ambitious Programmer and Technical Artist currently living in Barcelona. I studied Game Development at Barcelona University (ENTI). Also, I studied Cinema and production in the School of audiovisual media in Barcelona (EMAV).<br/> <br/>
          While I have enjoyed both designing and programming games. I'm currently working and Studying to increase my level. <br/> <br/>
          I love working together with enthusiastic people to create amazing games and programs, though I am very capable to work on my own. I am a social, self-motivated and adaptive learner with a broad skill set and a passion for programming & technical art. 
          </p>
          <a href="#contact" className="btn btn-primary-about">
            Let's Talk
          </a>
          <div className="lenguajes">

          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
