import React from "react";
import "./testimonials.css";
import AVTR1 from '../../assets/nicte.jpg'
import AVTR2 from '../../assets/jashan.jpg'
import AVTR3 from '../../assets/javi.jpg'
import AVTR4 from '../../assets/carlos.jpg'

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';



const data = [
  {
    avatar: AVTR3,
    name: 'Javier Torrón',
    role: 'CTO en Evita',
    review: 'Es un placer recomendar a Marc. Durante el tiempo que trabajó con nosotros como Junior DevOps, demostró un fuerte compromiso con la mejora continua de nuestros procesos de desarrollo y despliegue. Su habilidad para colaborar en equipo y su enfoque meticuloso en la automatización de tareas clave fueron especialmente destacables. Marc tiene un gran potencial y ha demostrado un rápido crecimiento en sus habilidades técnicas y su comprensión de las mejores prácticas de DevOps. '
  },
  {
    avatar: AVTR2,
    name: 'Jashanpreet Singh',
    role: 'Advanced App Engineering Sr Analyst en Accenture',
    review: 'Marc siempre ha destacado por ser una persona muy responsable y comprometida con los objetivos del equipo, además de demostrar día a día su experiencia en el campo de la programación, tanto en motores gráficos, como en lenguajes como C# y Java, además su capacidad para adaptarse a cualquier situación por adversa que fuera.'
  },
  {
    avatar: AVTR1,
    name: 'Nicte Mosso',
    role: 'Diseñadora Industrial',
    review: 'Durante el tiempo que trabajamos juntos, demostró ser un profesional excepcional. Su dedicación, habilidades y ética de trabajo son admirables. Siempre se destacó por  la resolución de problemas y la colaboración en equipo. Marc es un activo valioso para cualquier empresa y estoy seguro de que continuará teniendo un impacto positivo en su carrera. ¡Le deseo mucho éxito en el futuro!'
  },
  {
    avatar: AVTR4,
    name: 'Carlos Muñoz Terrón',
    role: 'Software Engineer Tech Lead',
    review: 'Me complace recomendar a Marc como un talentoso desarrollador junior en .NET. Durante su tiempo en nuestro equipo, demostró un compromiso excepcional con el aprendizaje y un rápido crecimiento en sus habilidades técnicas. Marc se destacó por su capacidad para abordar desafíos técnicos y su disposición para colaborar en proyectos importantes. '
  }
]



const Testimonials = () => {
  return (
    <section id="testimonials">

         <div className="link-svgmarker">
        <center>
          <a className="underline-text">
            {" "}
            Review from colleague 
            <svg className="link-svgline">
              <use xlinkHref="#svg_marker" />
            </svg>
          </a>
        </center>
      </div>

      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}>
        {
          data.map(({avatar, name, role, review},index) => {
            return(
              <SwiperSlide key={index} className="testimonials">
              <div className="client__avatar">
                <img src={avatar}/>
              </div>
              <h5 className="client__name">{name}</h5>
              <h5 className="client__role">{role}</h5>
              <small className="client__review">{review}
              </small>
            </SwiperSlide>
            )
          })
        }        
      </Swiper>
    </section>
  )
}

export default Testimonials
