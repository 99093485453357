import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
         <div className="link-svgmarker">
        <center>
          <a className="underline-text">
            {" "}
            What I Offer
            <svg className="link-svgline">
              <use xlinkHref="#svg_marker" />
            </svg>
          </a>
        </center>
      </div>

      <h2>Services</h2>

      <div className="container services__container">
        <article className="services">
          <div className="service__head">
            <h3>Full-Stack Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p> 2 years of experiencie in Back-end</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p> 1 year of experience in Front-end </p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>1 year of experiencie in DevOps</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>SQL and NOSQL DB experience</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Good C# and Java level</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Experience with Azure (Az-900).</p>
            </li>
          </ul>
        </article>



        <article className="services">
          <div className="service__head">
            <h3>Game Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Experience with Unity, Unreal Engine and Godot </p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>3D modeling with 3DS Max and MagicaVoxel</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Gamification methodologies</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Level Desing</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Good Story Narrative</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Rigging and skinning at beginner level</p>
            </li>
          </ul>
        </article>

        <article className="services">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Good level of Adobe Pack.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Video Production.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Creations of Gdd and Storyboards.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Visual content creation. </p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Branding and social media management.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Photography skills.</p>
            </li>
          </ul>
        </article>


      </div>

    </section>
  )
}

export default Services