import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/mnac.jpeg'
import IMG2 from '../../assets/bici.jpeg'
import IMG3 from '../../assets/toros.jpeg'
import IMG4 from '../../assets/jap.jpeg'
import IMG5 from '../../assets/estadio.jpeg'
import IMG6 from '../../assets/dandelion.png'


const data = [
{
  id:1,
  image: IMG1,
  title: 'Voxel MNAC Barcelona',
  demo:'https://www.artstation.com/artwork/6NQwBN'
},
{
  id:2,
  image: IMG2,
  title: 'Voxel Bicycle Enviroment',
  demo:'https://www.artstation.com/artwork/2qrJ1e'
},
{
  id:3,
  image: IMG3,
  title: 'Plaza de toros las Arenas Barcelona',
  demo:'https://www.artstation.com/artwork/Ry6L4D'
},
{
  id:4,
  image: IMG4,
  title: 'Voxel Japanese House',
  demo:'https://www.artstation.com/artwork/d05APX'
},
{
  id:5,
  image: IMG5,
  title: 'Voxel Stadium Inspired in Barcelona Camp Nou',
  demo:'https://www.artstation.com/artwork/Ked1mx'
},
{
  id:6,
  image: IMG6,
  title: 'Project Dandelion',
  demo:'https://www.youtube.com/watch?v=A6m6fGbfcSM&ab_channel=Maloboification'
}
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <div className="link-svgmarker">
        <center>
          <a className="underline-text">
            {" "}
            My Recent Work
            <svg className="link-svgline">
              <use xlinkHref="#svg_marker" />
            </svg>
          </a>
        </center>
      </div>

      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title,demo}) =>{
          return(
            <article key={id} className="portfolio__item">
            <div className='portfolio__item-image'>
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              < a href={demo} className='btn btn-portfolio2' target='_blank'>Demo</a>
            </div>
          </article>
          )         
        })
      }
      </div>
    </section>
  )
}

export default Portfolio