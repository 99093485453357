import React from 'react'
import './footer.css'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'



const Footer = () => {
  return (
    <footer>
      <a href='#'className='footer__logo'>Marc López Borràs</a>
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#services'>Services</a></li>
        <li><a href='#portfolio'>Portfolio</a></li>
        <li><a href='#testimonials'>Testimonials</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>


  <div className='footer__socials'>
    <a href='https://www.facebook.com/profile.php?id=61551503465200'><FaFacebookF/></a>
    <a href='https://instagram.com/chops.studios?igshid=MzRlODBiNWFlZA=='><FiInstagram/></a>
    <a href='https://twitter.com/ChopsStudios'><IoLogoTwitter/></a>
  </div>
  <div className="footer__copyright">
    <small>&copy; MarcLopezBorras. All rights reserved</small>
  </div>
</footer>
  )
}

export default Footer