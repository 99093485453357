import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {FiInstagram} from 'react-icons/fi'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com';



const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_yrjtqlw', 'template_6qvlk4r', form.current, 'Oz0ImJPwhjYEBP7ni')

    e.target.reset()
  };

  return (
    <section id='contact'>
      
      <div className="link-svgmarker">
        <center>
          <a className="underline-text">
            {" "}
            Get In touch
            <svg className="link-svgline">
              <use xlinkHref="#svg_marker" />
            </svg>
          </a>
        </center>
      </div>
      <h2>Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
          <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>malobo11@gmail.com</h5>
            <a href='mailto:malobo11@gmail.com' target='_blank'>Send a message</a>
          </article>
          <article className='contact__option'>
          <FiInstagram className='contact__option-icon'/>
            <h4>Instagram</h4>
            <h5>Chops.Studios</h5>
            <a href='https://instagram.com/chops.studios?igshid=MzRlODBiNWFlZA==' target='_blank'>Send a message</a>
          </article>
          <article className='contact__option'>
          <BsWhatsapp className='contact__option-icon'/>
            <h4>WhastApp</h4>
            <h5>+34651521843</h5>
            <a href='http://api.whatsapp.com/send?phone=651521843' target='_blank'>Send a message</a>
          </article>
        </div>
        {/*END OF CONTACT OPTIONS*/}
        <form ref={form} onSubmit={sendEmail}>
          <input type='text' name='name' placeholder='Your Full Name' required/>
          <input type='email' name='email' placeholder='Your Email' required/>
          <textarea name='message' rows='7' placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>   
    </section>
  )
}

export default Contact