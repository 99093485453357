import React from 'react' 
import {BsLinkedin} from 'react-icons/bs'
import {FaArtstation} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
const HeaderSocial = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/marclopezborras/" target="_blank"><BsLinkedin/></a>
        <a href="https://www.artstation.com/marclopezborras" target="_blank"><FaArtstation/></a>
        <a href="https://instagram.com/chops.studios?igshid=MzRlODBiNWFlZA==" target="_blank"><FiInstagram/></a>

    </div>
  )
}

export default HeaderSocial